<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container" v-if="applications">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['PermissionCreateManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">Выданные разрешения</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="table__filter--search">
												<label class="search__label">
													<input
														type="text"
														value=""
														name="filter_search"
														v-model="filters.search"
														:placeholder="$t('placeholder.search')"
													/>
													<button>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</label>
											</div>
										</div>
										<div class="table__filter--right">
											<router-link
												v-if="$store.getters.userPermission(['create_permission'])"
												:to="'/' + $i18n.locale + '/account/permission-list/create'"
												class="btn--link btn--link--plus"
											>
												Создать разрешение <img src="../../../assets/img/icon-plus.svg" />
											</router-link>
										</div>
									</div>

									<v-data-table
										class="table__block--wrap"
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
										:server-items-length="applicationsMeta.total"
										@update:options="pagination($event)"
									>
										<template v-slot:item.code="{ item }">
											{{ item.code }}
										</template>
										<template v-slot:item.name="{ item }">
											{{ item.name }}
										</template>
										<template v-slot:item.user_iin="{ item }">
											{{ item.user_iin }}
										</template>
										<template v-slot:item.action="{ item }">
											<router-link
												:to="'/' + $i18n.locale + '/account/permission-list/' + item.id"
												class="tb__table--btn tb__table--view"
												v-tooltip.top-center="{
													content: $t('tooltip.look'),
													class: ['tooltip__btn'],
												}"
											>
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</router-link>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api, urlApi } from '@/boot/axios'

export default {
	data() {
		return {
			urlApi: urlApi,

			applications: null,
			applicationsMeta: null,
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			blockElemet: null,
			unlockElemet: null,

			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},

			filterCategories: this.$t('orders.userList'),
			orderList: this.$t('orders.user'),

			headers: [
				{ text: 'Id', value: 'id' },
				{ text: 'Номер', value: 'code' },
				{ text: 'ИИН/БИН', value: 'user_iin' },
				{ text: 'Название', value: 'name' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],
		}
	},
	props: {
		user: {
			type: Object,
		},
	},
	methods: {
		apiGetUserDocumentList(page, per_page) {
			api
				.get('permission-application/list', { params: { page, per_page } })
				.then((response) => {
					if (response.data) {
						this.applications = response.data.data
            console.log(response.data)
						this.applicationsMeta = response.data.meta
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
		pagination(options) {
			this.apiGetReportingList(options.page, options.itemsPerPage)
		},
	},
	computed: {
		filteredlist() {
			return this.applications.filter((d) => {
				if (
					(d.name ? d.name.toLowerCase().includes(this.filters.search.toLowerCase()) : true) ||
					(d.code ? d.code.toLowerCase().includes(this.filters.search.toLowerCase()) : true)
				) {
					return true
				}
			})
		},
	},
	beforeMount() {
		// this.apiGetUserDocumentList(this.options.page, this.options.itemsPerPage)
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.users_parent'),
			}
		},
		meta: [],
	},
}
</script>
